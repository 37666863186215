<template>
  <div :class="`round-card ${round.match.map}`">
    <router-link :to="{ name: 'RoundSingle', params: { id: round.id }}">
      <div class="round-title">
        <h5>round {{round.round_number}}</h5>
        <h6>
          <div
            class="team-column"
            v-if="round && round.match && round.match.team_a && round.match.team_b"
          >
            <span v-if="round.match.team_a" class="team">{{round.match.team_a.name}}</span>x
            <span v-if="round.match.team_b" class="team">{{round.match.team_b.name}}</span>
          </div>
          <p v-else v-html="round.match.name || 'partida sem nome' "></p>
        </h6>
      </div>

      <div
        class="round-details"
      >vencedor {{ friendlyWinner(round.winner) }} - {{ friendlyReason(round.reason) }}</div>

      <div class="round-badges">
        <b-badge size="sm" variant="info">{{round.match.map}}</b-badge>
        <b-badge size="sm" variant="secondary" v-if="round.round_type">
          {{ round.round_type.replace('round_', '') }}
          <span
            v-if="round.side_trouble && ['round_force_buy', 'round_semi_eco', 'round_eco'].includes(round.round_type)"
          >{{ round.side_trouble.replace('trouble_', '') }}</span>
        </b-badge>
      </div>

      <div class="round-stats">
        <p class="stat" v-if="round.pageviews_count" v-b-tooltip.hover title="visualizações">
          <i class="far fa-eye"></i>
          {{round.pageviews_count}}
        </p>
        <p class="stat" v-if="round.comments_count" v-b-tooltip.hover title="comentários">
          <i class="fas fa-comments"></i>
          {{round.comments_count}}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MiniRound",
  props: {
    round: Object
  },
  methods: {
  }
};
</script>

<style lang="sass" scoped>
.round-card
  border: 1px solid #d1d1d1
  margin-bottom: 10px
  padding: 6px 10px
  border-radius: 4px
  background: #f9f9f9
  transition: all .1s
  position: relative

  &:hover
    transform: scale(1.05)

  .round-title
    h5
      margin: 0
    h6
      margin: 0
    .team-column
      span
        margin-right: 5px
  
  .round-details
    font-size: 12px
  
  .round-badges
    position: absolute
    top: 3px
    right: 5px
    .badge
      margin-left: 5px
  
  .round-stats
    position: absolute
    top: 30px
    right: 5px
    p.stat
      display: inline-block
      margin-left: 10px
  
  a
    color: #aaa
    &:hover
      text-decoration: none
      color: white
  p
    margin: 0
</style>
