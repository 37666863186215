<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-12">
        <b-alert
          show
          variant="light"
          class="p-0 welcome-alert text-center"
          v-if="finishedLoading && matches.length == 0"
        >
          <h4 class="alert-heading">{{ $t("HOME.welcome_alert.title") }}</h4>

          <p>{{ $t("HOME.welcome_alert.line1") }}</p>

          <!-- <h4 class="alert-heading">{{ $t("HOME.upload_alert.title") }}</h4> -->
          <p class="mb-0">{{ $t("HOME.upload_alert.line1") }}</p>

          <b-btn
            variant="danger"
            size="lg"
            class="mt-2 mb-4 f-700"
            :to="{ name: 'MatchNew' }"
            >{{ $t("HOME.upload_alert.submit") }}</b-btn
          >
        </b-alert>

        <div class="col-md-4" v-if="false">
          <div class="latest-comments" v-if="false">
            <div class="section-title">
              <h5>{{ $t("UTILITY.latest_comments") }}</h5>
            </div>

            <div class="comments-section">
              <div
                v-for="comment in comments"
                :key="comment.id"
                class="comment-card"
              >
                <div class="user-avatar">
                  <router-link
                    :to="{
                      name: 'UserSingle',
                      params: { id: comment.user.id },
                    }"
                  >
                    <img :src="comment.user.avatar.url" />
                  </router-link>
                </div>
                <div class="comment">
                  <!-- <div class="user-details">

                  </div>-->
                  <router-link
                    :to="{
                      name: 'RoundSingle',
                      params: { id: comment.commentable_id },
                    }"
                  >
                    <div class="comment-body">{{ comment.body }}</div>
                    <div class="comment-footer">
                      {{ $t("UTILITY.by") }}
                      <router-link
                        :to="{
                          name: 'UserSingle',
                          params: { id: comment.user.id },
                        }"
                      >
                        <span>{{ comment.user.display_name }}</span>
                      </router-link>
                      {{ $t("UTILITY.at") }} {{ comment.created_at_friendly }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="trending-rounds" v-if="false">
            <div class="section-title">
              <h5>{{ $t("UTILITY.trending_rounds") }}</h5>
            </div>
            <div class="trending-section">
              <MiniRound
                :round="round"
                v-for="round in trendingRounds"
                :key="round.id"
              ></MiniRound>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="finishedLoading && matches.length > 0">
      <div class="col-md-8">
        <h2>
          {{ $t("MY_MATCHES.title") }}
        </h2>
      </div>
      <div class="col-md-4 text-right">
        <b-btn variant="danger" :to="{ name: 'MatchNew' }">
          {{ $t("MY_MATCHES.send_match") }}
        </b-btn>
      </div>
    </div>

    <b-alert
      show
      variant="light"
      class="p-0"
      v-if="finishedLoading && matches.length > 0"
    >
      <p class="alert-heading" v-if="matchesRemaining > 0">
        {{
          $t("MY_MATCHES.remaining_matches", {
            count: matchesMax - matchesTotal,
          })
        }}
      </p>
      <p class="alert-heading" v-else>
        {{ $t("MY_MATCHES.matches_full") }}
      </p>

      <b-progress
        :value="matchesTotal"
        height="2rem"
        :max="matchesMax"
        show-value
      >
        <b-progress-bar :value="matchesTotal">
          <!-- {{ $t("MY_MATCHES.total_sent_matches") }} -->
          <strong>{{ matchesTotal }} / {{ matchesMax }}</strong>
        </b-progress-bar>
      </b-progress>

      <!-- <p
        class="mb-0"
      >Nessa área você consegue assistir suas partidas e as partidas públicas do FIGHTBACK</p>-->
    </b-alert>

    <div class="stats-table" v-if="finishedLoading && matches.length > 0">
      <!-- table-responsive -->

      <div class="table-actions">
        <div class="row">
          <div class="col-6 ml-auto text-right">
            <div class="with-selected" v-if="selected.length > 0">
              <!-- {{ selected.map(el => el.id ) }} -->
              <b-dropdown text="Com selecionados" class="m-md-2">
                <b-dropdown-item @click="compareSelected"
                  >Análise</b-dropdown-item
                >
                <!-- <b-dropdown-item>Second Action</b-dropdown-item>
                <b-dropdown-item>Third Action</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>

      <b-table
        hover
        :items="matches"
        :fields="fields"
        class="matches-table"
        selectable
        @row-selected="onRowSelected"
        v-if="finishedLoading && matches.length > 0"
      >
        <template v-slot:cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selecionado</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Não Selecionado</span>
          </template>
        </template>

        <template v-slot:cell(id)="data">
          <router-link
            :to="{ name: 'MatchSingle', params: { id: data.value } }"
          >
            <!-- class="forum-link" -->
            <div class="team-column">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play-btn-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"
                />
              </svg>
              <div v-if="data.item.team_a && data.item.team_b">
                <div v-if="data.item.team_a" class="team">
                  {{ data.item.team_a.name }}
                </div>
                x
                <div v-if="data.item.team_b" class="team">
                  {{ data.item.team_b.name }}
                </div>
              </div>
            </div>
            <p class="score m-0">
              {{ data.item.team_a_score }}:{{ data.item.team_b_score }}
            </p>
            <!-- <p class="m-0" v-html="data.item.name || $t('UTILITY.unnamed')"></p> -->
          </router-link>
        </template>

        <template v-slot:cell(map)="data">
          <router-link
            :to="{ name: 'MatchSingle', params: { id: data.value } }"
          >
            <p
              :class="`${data.item.map} mapbackground`"
              v-html="data.item.map"
            ></p>
          </router-link>
        </template>

        <!-- A virtual composite column
        <template v-slot:cell(nameage)="data">
          {{ data.item.name.first }} is {{ data.item.age }} years old
        </template>-->
      </b-table>

      <b-pagination
        size="sm"
        @change="changePage"
        align="center"
        :total-rows="totalRecords"
        v-model="page"
        :per-page="perPage"
        v-if="finishedLoading && matches.length > 0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import MiniRound from "@/components/MiniRound";
// import Header from '@/components/Header'
export default {
  name: "MyMatchList",
  data() {
    return {
      matches: [],
      finishedLoading: false,
      page: 1,
      perPage: 0,
      totalRecords: 0,
      fields: [
        { key: "selected", label: "", sortable: false },
        { key: "id", label: this.$t("UTILITY.match"), sortable: false },
        { key: "map", label: this.$t("UTILITY.map"), sortable: false },
        {
          key: "created_at_friendly",
          label: this.$t("UTILITY.added_at"),
          sortable: true,
        },
      ],
      selected: [],
      trendingRounds: [],
      comments: [],
      user: {},
      page: 1,
      perPage: 0,
      totalRecords: 0,
    };
  },
  computed: {
    matchesTotal: function () {
      return this.$store.getters.user &&
        this.$store.getters.user.subscription_package
        ? this.$store.getters.user.subscription_package.matches_total
        : 0;
    },
    matchesMax: function () {
      return this.$store.getters.user &&
        this.$store.getters.user.subscription_package
        ? this.$store.getters.user.subscription_package.matches_max
        : 0;
    },
    matchesRemaining: function () {
      return this.matchesMax - this.matchesTotal;
    },
  },
  watch: {
    page: function (newValue) {
      const params = new URLSearchParams(location.search);
      params.set("page", newValue);
      window.history.replaceState({}, "", `${location.pathname}?${params}`);
    },
  },
  methods: {
    initialize() {
      let params = new URLSearchParams(location.search);
      if (params.get("page")) this.page = parseInt(params.get("page"));

      this.loadRecords();
      this.loadComments();
      // this.loadTrending();
      this.loadUser();
    },
    loadRecords() {
      this.finishedLoading = false;
      this.$http
        .get(`matches.json?my_matches=true&page=${this.page}`)
        .then((response) => {
          this.matches = response.data.records;
          this.totalRecords = response.data.total_records;
          this.perPage = response.data.per_page;
          this.finishedLoading = true;
        });
    },
    changePage(page) {
      this.page = page;
      this.loadRecords();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    compareSelected() {
      let selection = this.selected.map((el) => el.id).join(",");
      this.$router.push(`/matches/compare?matches=${selection}`);
    },
    loadComments() {
      this.$http
        .get("latest_comments.json")
        .then((response) => (this.comments = response.data));
    },
    loadTrending() {
      this.$http
        .get("trending_rounds.json")
        .then((response) => (this.trendingRounds = response.data));
    },
    loadUser() {
      var self = this;
      this.$http.get("users/current_user.json").then((response) => {
        self.user = response.data;
        //   labels: response.data.latest_matches_stats.map(el => el.match_id),
      });
    },
  },
  mounted() {
    this.initialize();
  },
  components: {
    MiniRound,
  },
};
// Header
</script>

<style lang="sass" scoped>
a:hover
  text-decoration: none
  color: #333
.match-single
  p
    margin: 0
.team-column
  display: flex
  // justify-content: space-around
  div
    font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px

table
  td
    width: 80px
    background: #000 !important
// th:nth-child(4)
//   width: 80px

.mapbackground
  height: 40px
  color: white
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  font-weight: bold
  font-size: 13px
  border-radius: 4px

.match-single
  p
    margin: 0
.team-column
  display: flex
  // justify-content: space-around
  div
    font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px

.latest-comments
  .comments-section
    // border-top: 1px solid #ccc
    // margin-top: 20px
    // padding-top: 20px
    .comment-card
      display: flex
      align-items: center
      margin: 10px 0
      .user-avatar
        width: 48px
        height: 48px
        min-width: 48px
        display: block
        overflow: hidden
        // padding: 5px
        margin: 5px
        margin-right: 15px
        img
          width: 100%
          height: auto
          border-radius: 250%
          // min-width: 48px
      a
        color: #666
        &:hover
          color: #222
      .comment
        background: #f1f1f1
        flex-grow: 1
        padding: 4px 8px
        border-radius: 4px
        position: relative

        &:before
          content: ''
          height: 0
          width: 0
          position: absolute
          display: block
          border-width: 9px 7px 9px 0
          border-style: solid
          border-color: transparent #f1f1f1
          top: 8px
          left: -6px
          // background: #f1f1f1

        .comment-body
          line-height: 125%
        .user-details
          span
            font-weight: bold
        .comment-footer
          font-size: 12px
          a
            font-weight: bold
    .new-comment
      margin-top: 30px
.trending-rounds
  margin-top: 20px

.latest-matches-widget
  display: flex
  justify-content: space-around
  align-items: center
  flex-wrap: wrap

  .match
    width: 24%
    color: white
    display: flex
    justify-content: center
    flex-wrap: wrap
    border-radius: 5px
    padding: 5px 0

    .kdr
      width: 100%
      text-align: center
    .map
      width: 100%
      text-align: center
      font-size: 11px
    .score
      width: 100%
      text-align: center

.welcome-alert
  h4
    font-size: 3rem
  p
    font-size: 1.1rem
  p:last-of-type
    margin-top: 4rem
    font-family: $font
    font-size: 2rem
    font-weight: 700
</style>
